.customers-menu{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;   

}

.user-name{
    font-size: 20px;
    vertical-align: middle;
    line-height: normal;
}



.logo{
    height: 35px;
    align-self: flex-end;
    margin-right: auto;
    margin-bottom: 20px;
}

.line-break{
    width: 100%;
}
.customer-search{
    width: 300px;
    display: flex;
}
.search-text{
    outline: none;
    font-size: 20px;
    padding: 5px;
    width: 100%;
    height: 32px;
    border: .8px solid black;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: none;
}
/* .btn:focus, .logout:focus {
    outline: 3px solid rgb(95, 223, 95);
} */
.serch-btn-customers{
    margin: auto;  
    border: 1px solid black;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 100%;
}
.clear-search{
    border: 1px solid black;
    border-right: none;
    border-left: none;
    width: 40px;
    text-align: center;
}
.clear-search-btn{
    outline: none;
    background-color: unset;
    background-image: url('../assets/clear.png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px; 
    border: none;
    width: 100%;
}
.btn:hover{
    cursor: pointer;
}
.hidden{
    display: none;
}
.customer-add{
    display: block;
    background-image: url('../assets/add.png');
    height: 25px;
    width: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    background-color: unset;
    margin: auto 0;
    margin-right: 20px;
    outline: none;
    border-radius: 50%;
}
.customer-headers{
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    box-shadow: 0px 9px 10px -10px #a29e9e;
    position: sticky;
    top: 0;
    background-color: white;
}

.customer-title{
    font-size: 20px;
    text-decoration: underline solid rgb(224, 218, 218);
    width: 33.33%;
    text-align: center;
}
.customer{
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    user-select: none;
}
.customer > div{
    width: 33.33%;
    text-align: center;
}
.customer:nth-child(even){
    background-color: #F2F2F2;
}

a{
    text-decoration: none;
    color: unset;
}

@media screen and (max-width: 400px){

}
@media screen and (min-width: 700px){
    .customer-title{
        font-size: 22px;
    }
    .customer{
        font-size: 18px;
    }
}
@media screen and (min-width: 1000px){
    .customers-con{
        width: 1000px;
        margin: auto;
        padding: 20px;
    }
    .customer-title{
        font-size: 25px;
    }
    .customer{
        font-size: 20px;
    }
}