.login-con{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
}

.login-con > #logo{
    height: 40px;
}
.login-con > h1{
    font-family: arial, sans-serif;
    font-weight: normal;
    font-size: 25px;
    margin: 20px 0 70px 0;
}
.login-field{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}
.login-field > input{
    margin-top: 2px;
    font-size: 17px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
}
.login-field > input:focus{
    outline: 1px auto #47A212;
    outline-offset: 0;
}

#btn{
    margin-top: 80px;
    background-color: #47A212;
    color: white;
    font-size: 20px;
    border: 1px solid transparent;
    outline: none;
    border-radius: 4px;
    padding: 6px 25px;
    transition: background-color .2s ease-in-out;
}

#btn:hover{
    background-color: #4e9027;
    cursor: pointer;
}

#btn:focus{
    background-color: #4e9027;
}

.incorrect{
    display: flex;
    width: 200px;
    padding: 10px 20px;
    background-color: #ffe3e6;
    border-radius: 4px;
    border: 1px solid rgb(197 108 113);
}
.incorrect > div{
    margin-right: auto;
    user-select: none;
    color: rgb(197 108 113);
}
.incorrect > div:hover{
    cursor: pointer;
}
@media screen and (min-width: 600px){
    .login-con{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: #0000005c 0 0 7px 1px;
        width: 480px;
    }
}