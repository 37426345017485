.customer-top{
    box-shadow: 0px 9px 10px -10px #a29e9e;
    display: flex;
    padding: 10px 20px;
    height: 70px;
}
.customer-top > .title{
    font-size: 38px;
    line-height: 55px;
}
.customer-link{
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    margin: 0 20px;
    position: relative;
}
.info-link{
    background-image: url('../assets/personal.png');
    background-size: 30px;
    
}
.info-link::after{
    content: "פרטים";
    position: absolute;
    bottom: -20px;
    margin: auto;
    font-size: 14px;
}
.meetings-link{
    background-image: url('../assets/meating.png');
    background-size: 25px;
}
.meetings-link::after{
    content: "פגישות";
    position: absolute;
    bottom: -20px;
    margin: auto;
    font-size: 14px;
}
.back-link{
    background-image: url('../assets/back.png');
    background-size: 30px;
    margin-right: 10px;
}
.margin-right-auto{
    margin-right: auto;
}

.circle-link{
    border: 1px solid #707070;
    border-radius: 50%;
}
.active{
    background-color: #E5E5E5;
}
.add-btn{
    width: 160px;
    margin: 10px auto;
}
.info{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}
.separate{
    border-bottom: 1px solid black;
}
.field{
    margin: 10px 0;
}
.field > .head{
    margin-bottom: 5px;
    font-size: 20px;
}
.field > .input{
    width: 300px;
    height: 36px;
    padding: 3px 6px;
    font-family: Arial, sans-serif;
    font-size: 15px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
}
.field > .input:focus{
    outline: none;
}
.field > textarea{
    resize: none;
    height: 90px !important;
}
.notes{
    height: 90px !important;
    background-color: #dddddd !important;
}
.back-a{
    height: 37px;
    margin-top: auto;
    margin-bottom: auto;
}

.contorls{
    margin: 60px 0;
    display: flex;
    justify-content: center;
}
.action{
    background-color: #47A212;
    color: white;
    font-size: 19px;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 6px 25px;
    transition: background-color .2s;
}

.action:hover{
    background-color: #4e9027;
    cursor: pointer;
}

.action-delete{
    background-color: #d11a2a;
    color: white;
    font-size: 20px;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 6px 25px;
    transition: background-color .2s;
    margin-right: 10px;
}
.action-delete:hover{
    background-color: #b91825;
    cursor: pointer;
}

.confirm-overlay{
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 99;
    background-color: rgba(0,0,0,0.5);
}
.confirm-container{
    background-color: white;
    width: 400px;
    height: 200px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 20px;
    text-align: center;

}
.confirm-message{
    font-size: 25px;
    margin-bottom: 30px;
}

/* <div className="message">
            <div className="message-text">עודכן הכל טוב </div>
            <input type="button" value="X" onClick={props.stop}/>
        </div> */

.message{
    position: fixed;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
    background-color: #323232;
    border-radius: 4px;
    color: white;
    width: 200px;
    padding: 9px;
    display: flex;
}
.message > input   {
    margin-right: auto;
    background-color: unset;
    border: none;
    color: white;
    outline: none;
    font-size: 18px;
}
@media screen and (min-width: 700px){
    .field > .head{
        font-size: 26px;
    }
    .field > .input{
        font-size: 20px;
        width: 400px;
    }
}
@media screen and (min-width: 1000px){
    .customer-con{
        width: 1000px;
        margin: auto;
    }
}
