.meetings-top{
    width: 100vw;
    display: flex;
    padding: 20px 100px;
}
.meetings-top >.left-side{
    margin-right: auto;
    display: flex;
    align-items: center;
}
.filters-con{
    display: flex;

}
.filter-con{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin: 0 20px;

}
.filters-con > .search-btn{
    background-size: 25px;
}
.filter-title{
    margin-bottom: 7px;
    min-height: 27px;
}
.filter{
    width: 220px;
    height: 35px;
    padding: 3px 6px;
    font-family: Arial, sans-serif;
    font-size: 20px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    outline: none;
}
.filters{
    display: flex;
    flex-direction: column;
}
.filters label{
    height: 30px;
    margin: 0 5px;
}
.users{
    height: 30px;
}
table{
    border-collapse: collapse;
    width: 100vw;
    font-size: 17px;
}
th{
    position: sticky;    
    top: 0;
    background-color: white;
    box-shadow: 0px 10px 6px -6px #00000082;
}
td{
    padding:8px;
}
tr{
    border-bottom: 1px solid black;
    
}
tr:nth-child(odd){
    background-color: #E5E5E5;
}
.remark{
    -webkit-user-select: none;
}

.pop-up{
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2; 
    cursor: pointer;
} 
.remark-active{
    display: block;
}

.pop-up > div{
    height: 400px;
    width: 500px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
}

.pop-up textarea{
    width: 100%;
    height: 80%;
    resize: none;
    outline: none;
    font-family: Arial, sans-serif;
    font-size: 20px;
}

.pop-up input{
    display: block;
    margin: 10px auto;
}